<template>
    <!-- Parent div needed to be able to scroll to bottom message regardless of canViewMessage    -->
    <div>
        <transition v-if="canViewMessage" name="fade">
            <div class="chat-msg-container" :class="{ outgoing }">
                <div class="chat-msg">
                    <div class="icon-and-msg">
                        <div v-if="!outgoing" class="person-circle">
                            <AvatarOrInitials :user="initials" :size="40" />
                        </div>

                        <!-- message bubble -->
                        <div class="bubble" :class="[message.type]">
                            <!-- first reply from question answer survey -->
                            <div
                                v-show="
                                    isAdmin && message?.has_moderated_content
                                "
                                class="btn-moderated"
                            >
                                <img src="/img/magic-wand.svg" />
                                <a
                                    :text="viewCommentText"
                                    @click="toggleComment"
                                ></a>
                            </div>
                            <template v-if="message.type === 'question'">
                                <template
                                    v-if="
                                        Object.keys(message.conversational)
                                            .length
                                    "
                                >
                                    <!-- CS2GO-768 for update_score we hide the row and only display a `(update)` suffix in `Rating` label -->
                                    <div
                                        v-for="question in message.conversational"
                                        v-show="shouldShowQuestion(question)"
                                        :key="question.key"
                                    >
                                        <div
                                            data-uk-tooltip
                                            :title="
                                                getQuestionTooltip(
                                                    question.key,
                                                    question
                                                )
                                            "
                                        >
                                            <div
                                                v-if="
                                                    isAiQuestion(question.key)
                                                "
                                                class="title"
                                            >
                                                {{ question.question_text }}
                                            </div>
                                            <div v-else class="title">
                                                {{
                                                    friendlyFieldName(
                                                        question.key
                                                    )
                                                }}
                                            </div>

                                            <div v-if="isAdmin" class="quote">
                                                <transition name="slide">
                                                    <div
                                                        v-if="showComment"
                                                        v-html="
                                                            getQuestionHtml(
                                                                question.key,
                                                                question,
                                                                showOriginalComment
                                                            )
                                                        "
                                                    />
                                                </transition>
                                            </div>
                                            <div
                                                v-if="!isAdmin"
                                                class="quote"
                                                v-html="
                                                    getQuestionHtml(
                                                        question.key,
                                                        question
                                                    )
                                                "
                                            />
                                        </div>
                                        <div
                                            v-if="
                                                (question.key === 'comment' &&
                                                    message.original_comment) ||
                                                question?.original_message
                                            "
                                            class="moderated-container--tag"
                                        >
                                            <img src="/img/magic-wand.svg" />
                                            Moderated by NiceAI
                                        </div>
                                    </div>
                                </template>
                                <span v-else v-html="message.comment" />
                            </template>

                            <!-- review message -->
                            <template v-else-if="message.type === 'review'">
                                <span class="pre-line">
                                    {{ replaceSpecialChars(message.message) }}
                                </span>
                            </template>

                            <!-- internal note -->
                            <template v-else-if="message.type === 'note'">
                                <img
                                    class="note-icon"
                                    src="@/assets/img/Icons/icon_note_yellow.svg"
                                />
                                <span
                                    @click="onClickHashtag"
                                    v-html="
                                        nl2br(
                                            parseMentionsAndTags(message.text)
                                        )
                                    "
                                />
                                <span
                                    v-if="canDeleteNote"
                                    class="delete-message"
                                >
                                    <span
                                        class="uk-text-muted"
                                        data-uk-tooltip
                                        title="Delete this note"
                                        @click="doDeleteNote"
                                    >
                                        <img
                                            class="delete-cross"
                                            src="@/assets/img/Icons/x.svg"
                                        />
                                    </span>
                                </span>
                            </template>

                            <!-- workflow/automation -->
                            <template v-else-if="message.type === 'automation'">
                                <img
                                    class="automation-icon"
                                    src="@/assets/img/Icons/flash-purple.svg"
                                />
                                <span
                                    v-if="expanded"
                                    class="cursor"
                                    @click="expanded = false"
                                    v-html="message.body"
                                />
                                <span
                                    v-else
                                    class="cursor"
                                    @click="expanded = true"
                                    >{{ message.message }}</span>
                                <span
                                    v-if="secondsToDelete > 0 && isUserBasic"
                                    class="delete-message"
                                >
                                    <span
                                        class="uk-text-muted"
                                        data-uk-tooltip
                                        title="Delete from queue"
                                        @click="doDeleteWorkflow"
                                    >
                                        <img
                                            class="delete-cross"
                                            src="@/assets/img/Icons/x.svg"
                                        />
                                    </span>
                                </span>
                            </template>

                            <template v-else-if="isShoutout">
                                <Quote :text="questionText" />
                                <span
                                    v-html="
                                        decodeHtmlEntities(
                                            replaceSpecialChars(message.message)
                                        )
                                    "
                                />
                            </template>

                            <template
                                v-else-if="message.type === 'case_update'"
                            >
                                <img
                                    class="case-update-icon"
                                    src="@/assets/img/Icons/image-user.svg"
                                />
                                <span>
                                    {{ message.message }}
                                </span>
                            </template>

                            <template
                                v-else-if="message.type === 'case_assignment'"
                            >
                                <img
                                    class="case-assignment-icon"
                                    src="@/assets/img/Icons/image-user.svg"
                                />
                                <span>
                                    {{ message.message }}
                                </span>
                            </template>

                            <!-- normal chat messages -->
                            <template v-else>
                                <span class="pre-line">
                                    {{
                                        decodeHtmlEntities(
                                            replaceSpecialChars(message.message)
                                        )
                                    }}
                                </span>
                            </template>
                        </div>
                    </div>
                    <div v-if="!isShoutout" class="emoji-container">
                        <!-- emoji -->
                        <EmojiReaction
                            v-if="hasNetworkConnection"
                            :is-mobile="isMobile"
                            :class="{
                                bottom: message.type === 'question',
                                top: message.type !== 'question',
                                left:
                                    (!outgoing &&
                                        textLength >
                                            maxLengthToReverseDirection) ||
                                    (outgoing &&
                                        textLength <
                                            maxLengthToReverseDirection),
                                right:
                                    (outgoing &&
                                        textLength >
                                            maxLengthToReverseDirection) ||
                                    (!outgoing &&
                                        textLength <
                                            maxLengthToReverseDirection),
                            }"
                            :emojis="message.emojis"
                            :relid="message.id"
                            :type="message.type"
                            :firstname="customData.user_firstname"
                            :single-mode="1"
                        />
                    </div>
                </div>

                <!-- time, name, seen -->
                <div class="info">
                    <template v-if="showMessageUser">
                        <span class="name">{{
                            message.name || message.from_name
                        }}</span>
                        <span class="dot">.</span>
                    </template>
                    <!-- review stars -->
                    <template
                        v-if="
                            message.type === 'review' && message.review_rating
                        "
                    >
                        {{ '⭐'.repeat(message.review_rating) }}
                        Reviewed
                    </template>

                    <!-- automation sent in future -->
                    <span
                        v-if="
                            message.type === 'automation' && secondsToDelete > 0
                        "
                        data-uk-tooltip
                        :title="message.ftime"
                    >
                        Sending {{ timeSince(message.time) }}
                    </span>

                    <template v-if="isShoutout && secondsToDelete > 0">
                        <span class="dot">.</span>
                        &nbsp;<FontAwesomeIcon
                            id="clock-icon"
                            :icon="faClock"
                        />&nbsp;<span>Scheduled at {{ message.ftime }}</span>
                        <template v-if="!isMobile">
                            <span
                                class="delete-message"
                                @click="doDeleteShoutout"
                            >
                                DELETE
                            </span>
                        </template>
                    </template>

                    <span
                        v-if="secondsToDelete < 0"
                        class="time"
                        :title="message.ftime"
                        data-uk-tooltip
                    >
                        {{ timeSince(message.time) }}
                    </span>

                    <!-- show/hide publish review link -->
                    <div
                        v-if="
                            message.type === 'review' && message.review_rating
                        "
                    >
                        <span v-if="reviewReplied">
                            Published on Reviews site
                        </span>
                        <a
                            v-else-if="
                                !chatStatus.reply_review || !chatStatus.editing
                            "
                            class="reply-review"
                            @click="onChangeReplyReview(true)"
                        >
                            Write Public Reply
                        </a>
                    </div>
                    <template v-else-if="reviewReplied">
                        <span class="dot">.</span> Published on Reviews site
                    </template>
                    <template
                        v-if="
                            message.type === 'chat' &&
                            outgoing &&
                            secondsToDelete > 0
                        "
                    >
                        <span class="dot">.</span>
                        <span
                            :class="{ undo: undoText === 'Undo' }"
                            :data-uk-tooltip="undoText === 'Undo'"
                            title="Unsend this message - but be quick ⌛"
                            @click="undoChat"
                        >
                            {{ undoText }}
                        </span>
                    </template>
                    <template
                        v-if="
                            outgoing &&
                            message.type === 'chat' &&
                            message.opened
                        "
                    >
                        <span class="dot">.</span>
                        <span
                            data-uk-tooltip
                            class="eyes"
                            :title="message.fopened"
                        >
                            👀
                        </span>
                    </template>
                    <div>
                        <EmojiReaction
                            v-if="hasNetworkConnection"
                            :class="{
                                bottom: message.type === 'question',
                                top: message.type !== 'question',
                                left: outgoing,
                                right: !outgoing,
                            }"
                            :emojis="message.emojis"
                            :relid="message.id"
                            :type="message.type"
                            :firstname="customData.user_firstname"
                            :single-mode="0"
                        />
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { getQuestionClass, getQuestionText } from '@/domain/NPS'
import { timeSince } from '@/utils/time'
import { deleteNote, deleteReply } from '@/api/chat'
import { deleteMessage } from '@/api/notices'
import { Action, Getter, namespace } from 'vuex-facing-decorator'
import { ChatStatus } from '@/entities/chat'
import EmojiReaction from '@/components/Chat/EmojiReaction.vue'
import { ConvUIQuestions } from '@/pages/dashboard/entities/dash'
import { isFiveScoreQuestionType } from '@/utils/globalVariables'
import AvatarOrInitials from '@/components/WebView/AvatarOrInitials.vue'
import { UserDetail } from '../../entities'
import fname, { htmlSanitizer, interpolateData } from '@/utils/string'
import Quote from '@/components/Notices/Quote.vue'
import { FontAwesomeIcon } from 'fontawesome/vue-fontawesome'
import { UserDetails } from '@/mobile/src/types/auth'
import { faClock } from 'fontawesome/free-regular-svg-icons'
import { getHashtagWhitelist } from '@/api/hashtagWhitelist'
import { Permissions } from '@/mobile/src/types/auth'
import { finalValueChecker } from '@/utils/function'

const AuthModule = namespace('auth')

@Component({
    components: {
        EmojiReaction,
        AvatarOrInitials,
        Quote,
        FontAwesomeIcon,
    },
    emits: ['undo-chat'],
})
export default class ChatMessage extends Vue {
    public timeSince = timeSince
    public expanded = false // if workflow should expand
    public undoText = 'Undo'
    public viewCommentText = 'View original'
    public showOriginalComment = false
    public showComment = true

    public hashtagWhitelist = []

    private readonly faClock = faClock

    @Prop() public message
    @Prop() public questionText
    @Prop({ type: Function }) public onChangeReplyReview
    @Prop({ type: Object }) public chatStatus!: ChatStatus
    @Prop({ type: Object }) public customData!: {
        answer: string
        survey_template: string
        question_type: string
        custom_field_labels: string
    }
    @Prop({ type: Object }) public person!: UserDetail
    @Prop({ type: Boolean }) public canDeleteNote!: boolean
    @Prop({ type: Boolean }) public isUserBasic!: boolean
    @Prop() public convQuestions!: ConvUIQuestions
    @Prop({ type: Boolean })
    hasNetworkConnection!: boolean

    @AuthModule.Getter public details!: UserDetails

    @Action public loadChat

    @Getter private permissions
    @Getter private $isMobile

    @Getter public $companyVars

    public get isFiveScore() {
        return isFiveScoreQuestionType(this.message.question_type)
    }

    public get isShoutout() {
        return this.message.type === 'shoutout'
    }

    public get isQuestion() {
        return this.message.type === 'question'
    }

    public get isChat() {
        return this.message.type === 'chat'
    }

    public get sender() {
        if (this.isChat) {
            return this.message.from_name
        }
        return this.message.name
    }

    public get initials() {
        if (this.isQuestion || this.isChat) {
            return this.person
        } else {
            return {
                id: this.message.user_id,
                name: this.sender,
                avatar: this.message.avatar,
            }
        }
    }

    public get showMessageUser() {
        return !this.isQuestion
    }

    public get textLength() {
        let msg
        if (this.isQuestion) {
            // to determine the emoji popu poisition
            // is mobie => consider as a long message => display emoji at left
            // is desktop => consider as a short message => display emoji at right
            return this.isMobile
                ? this.maxLengthToReverseDirection + 1
                : this.maxLengthToReverseDirection - 1
        }
        if (this.message.type === 'note') {
            msg = this.message.text
        } else {
            msg = this.message.message || ''
        }
        if (msg) {
            return msg.length
        }
        return 0
    }

    public get outgoing() {
        return Number(this.message.outgoing)
    }

    public get reviewReplied() {
        return Number(this.message.review_replied)
    }

    public get secondsToDelete() {
        return Number(this.message.time) - new Date().getTime() / 1000
    }

    public get isMobile() {
        return window.innerWidth < 500
    }

    // whether the score was updated
    public get isScoreUpdated() {
        if (this.message && this.message.conversational) {
            return this.message.conversational['update_score']
        }
        return false
    }

    public get canViewMessage() {
        // checks view permission according to message type
        switch (this.message?.type) {
            case 'note':
                if (this.$isMobile) {
                    // We can't access the permissions on desktop this way :(
                    return this.permissions.includes('ROLE_VIEW_NOTES')
                }
                return this.details.permissions.includes('ROLE_VIEW_NOTES')
            default:
                return true
        }
    }

    public get maxLengthToReverseDirection() {
        return this.isMobile ? 14 : 30
    }

    public async beforeMount() {
        if (this.$companyVars.has_hashtag_whitelist) {
            await getHashtagWhitelist().then(({ data }) => {
                this.hashtagWhitelist = data.data ?? []
            })
        }
    }

    public mounted() {
        if (this.secondsToDelete > 0) {
            setTimeout(() => {
                this.undoText = 'Sent'
            }, this.secondsToDelete * 1000)
        }
    }

    public async undoChat() {
        if (
            this.undoText === 'Sent' ||
            !confirm('Are you sure you want to delete this email?')
        ) {
            return
        }

        this.$emit('undo-chat', this.message)

        await deleteReply(Number(this.message.id))
        this.loadChat()
    }

    public async doDeleteNote() {
        if (confirm('Are you sure you want to delete this note?')) {
            await deleteNote(Number(this.message.id))
            this.loadChat()
        }
    }

    public async doDeleteShoutout() {
        if (confirm('Are you sure you want to delete this shoutout?')) {
            await deleteMessage(Number(this.message.notice_id))
            this.loadChat()
        }
    }

    public async doDeleteWorkflow() {
        if (confirm('Delete this queued email?')) {
            const response = await deleteReply(Number(this.message.id))
            if (response.data.msg) {
                alert(response.data.msg)
            }
            this.loadChat()
        }
    }

    public friendlyFieldName(str) {
        let suffix = ''
        if (str === 'initial_score') {
            str =
                this.customData.question_type === 'nps'
                    ? 'NPS Rating'
                    : 'Rating'

            // if there's `update score` item, we display a `(update)` suffix for the `Rating` label
            if (this.isScoreUpdated) {
                suffix = ' (updated)'
            }
        }

        const label = this.customData.custom_field_labels[str + '_c']
            ? this.customData.custom_field_labels[str + '_c']
            : str

        return fname(label) + suffix
    }

    public isAiQuestion(str) {
        return str.includes('ai_question_')
    }

    public getQuestionTooltip(param, data) {
        const value = data.value
        const param_c = param + '_c'
        // scorecard mapping
        const mapping = this.message.scorecardBadgeMapping[param_c]
        const score = parseInt(this.customData.answer, 10)

        // special case for scorecard question, need to check if it's positive or negative
        if (value && mapping) {
            const isPositive = this.isFiveScore ? score >= 4 : score >= 9
            const type = isPositive ? 'positive' : 'negative'
            try {
                const replaced = htmlSanitizer(
                    mapping[type].questions[this.customData.survey_template]
                )
                return interpolateData(
                    replaced,
                    this.customData,
                    this.customData.question_type
                )
            } catch (e) {
                return ''
            }
        }

        try {
            const replaced = htmlSanitizer(getQuestionText(data.question_text))
            return interpolateData(
                replaced,
                this.customData,
                this.customData.question_type
            )
        } catch (e) {
            return ''
        }
    }

    public decodeHtmlEntities(str) {
        const textArea = document.createElement('textarea')
        textArea.innerHTML = str
        return textArea.value
    }

    public getQuestionHtml(param, data, originalComment = false) {
        let finalValue // 1-10 for nps, text for free text, etc
        const param_c = param + '_c'
        const mapping = this.message.scorecardBadgeMapping[param_c]
        const convQuestionsLoaded = Object.keys(this.convQuestions).length > 0
        switch (param) {
            case 'initial_score':
                finalValue = this.customData.answer
                break
            case 'comment':
                finalValue = this.decodeHtmlEntities(
                    originalComment
                        ? this.message.original_comment
                        : this.message.comment
                )
                break
            default:
                if (mapping) {
                    const value = this.customData[param_c]
                    const score = parseInt(this.customData.answer, 10)
                    const isPositive = this.isFiveScore
                        ? score >= 4
                        : score >= 9
                    const badgeType = isPositive ? 'positive' : 'negative'

                    finalValue = value

                    if (
                        badgeType in mapping &&
                        'options' in mapping[badgeType]
                    ) {
                        const options = mapping[badgeType].options
                        if (Array.isArray(options)) {
                            // Arrays use auto-incrementing 0-index.
                            // We need to traverse it and find the elem with the matching value,
                            // which must also have a sibling "label" prop.
                            const option = options.find(
                                (option) =>
                                    option.value === finalValue[0] &&
                                    option.label
                            )
                            if (option) {
                                finalValue = option.value
                            }
                        } else {
                            // Objects use the value itself as the index.
                            // We just check if the "value" and "label" props exist.
                            finalValue = finalValueChecker(options, finalValue)
                        }
                    } else if (Array.isArray(finalValue)) {
                        finalValue = finalValue[0]
                    }
                } else if (this.convQuestions[param_c]) {
                    // currently there isn't any supported method for editing open questions,
                    // @todo when editing open questions is enabled, obtain data appropriately
                    // For List Question, multiple option keys will be delimited by comma here.
                    const multiOptionKeys = data.value.split(',')

                    finalValue = ''
                    multiOptionKeys.forEach((optionKey) => {
                        if (
                            param_c in this.convQuestions &&
                            'options' in this.convQuestions[param_c] &&
                            optionKey.trim() in
                                this.convQuestions[param_c].options
                        ) {
                            finalValue +=
                                this.convQuestions[param_c].options[
                                    optionKey.trim()
                                ] + '<br>'
                        }
                    })
                    // If finalValue is empty, bring back the data.value.
                    finalValue =
                        finalValue == ''
                            ? originalComment && data?.original_message
                                ? data.original_message
                                : data.value
                            : finalValue
                } else {
                    finalValue =
                        originalComment && data?.original_message
                            ? data.original_message
                            : data.value
                }
        }

        // finalValue must be a string for htmlSanitizer
        if (Array.isArray(finalValue)) {
            finalValue = finalValue.join('<br/>')
        }

        const sanitizedValue = this.decodeHtmlEntities(
            htmlSanitizer(finalValue)
        )

        // display a "dot" for score
        let dot = ''
        if (param === 'initial_score') {
            const questionClass = getQuestionClass(
                finalValue,
                this.customData.question_type
            )
            dot = `<span class="dot ${questionClass}"></span>`
        }

        return `<div>${dot}${sanitizedValue}</div>`
    }

    public parseMentionsAndTags(textToParse) {
        let parsedText = textToParse.replace(
            /\[@([^\]]+)\]\(user:([^\)]+)\)/g,
            '<span class="mention" data-uk-tooltip title="$2">@$1</span>'
        )
        if (this.$companyVars.has_hashtag_whitelist) {
            parsedText = parsedText.replace(
                /\[#([^\]]+)\]\(tag:([^\)]+)\)/g,
                (match, name) => {
                    //@ts-ignore
                    if (this.hashtagWhitelist.includes(name)) {
                        return ' <span class="mention">#' + name + '</span>'
                    }
                    return ' #' + name
                }
            )
        } else {
            parsedText = parsedText.replace(
                /\[#([^\]]+)\]\(tag:([^\)]+)\)/g,
                ' <span class="mention">#$1</span>'
            )
        }

        return parsedText
    }

    public stripTags(text) {
        const div = document.createElement('div')
        div.innerHTML = text
        return div.textContent || div.innerText || ''
    }

    public nl2br(text) {
        return text.replace(/(?:\r\n|\r|\n)/g, '<br>')
    }

    public replaceSpecialChars(text) {
        return text?.replace(/<br\s*[\/]?>/gi, '\n').replace(/&amp;/, '&') ?? ''
    }

    public onClickHashtag(e) {
        if (e.target && e.target.matches('.mention')) {
            let hashtag = e.target.innerText
            if (hashtag.indexOf('#') !== -1) {
                hashtag = hashtag.replace(/^#/, '')
                parent.window.location.href = '/responses?tag=' + hashtag
            }
        }
    }

    public toggleComment() {
        this.showComment = !this.showComment
        setTimeout(() => {
            this.showOriginalComment = !this.showOriginalComment
            this.showComment = !this.showComment
        }, 500)

        if (this.viewCommentText == 'View original') {
            this.viewCommentText = 'View moderated'
        } else {
            this.viewCommentText = 'View original'
        }
    }

    public get isAdmin() {
        return this.details?.permissions.includes(Permissions.RoleAdminBasics)
    }

    public shouldShowQuestion(question) {
        // Don't show if it's an update_score
        if (question.key === 'update_score') {
            return false
        }

        // Don't show comment if it matches ai_question_1
        if (question.key === 'comment') {
            const aiQuestion = this.message?.conversational?.['ai_question_1']
            const originalComment = this.message?.original_comment

            if (aiQuestion && originalComment) {
                return aiQuestion.value !== originalComment
            }
        }

        return true
    }
}
</script>

<style scoped lang="less">
@import '~@/styles/breakpoints.less';
@import '~@/styles/layout.less';
@import '~@/styles/palette.less';
@import '~@/styles/rain/colour.less';
@import '~@/styles/rain/variables.less';

.btn-moderated {
    background-color: #2e5bff;
    padding: 7px 10px;
    margin-top: 10px;
    float: right;
    color: white;
    border-radius: 4px;

    img {
        filter: brightness(0) invert(1);
        margin-right: 5px;
        margin-top: -3px;
    }
    a {
        color: @white;
    }
}

.pre-line {
    white-space: pre-line;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

.slide-enter-active,
.slide-leave-active {
    max-height: 1000px;
    overflow-y: hidden;
    transition: all 0.3s;
}

.slide-enter-from,
.slide-leave-to {
    max-height: 0;
}

.shoutout-info {
    span {
        margin: 0 10px;
    }
}

.chat-msg-container {
    padding-top: 8px;

    &:first-child {
        padding-top: 20px;
    }

    .chat-msg {
        display: flex;
        align-items: center;

        .icon-and-msg {
            display: flex;
            align-items: flex-end;
            max-width: 70%;
        }

        .emoji-container {
            padding: 5px;
        }

        .person-circle {
            min-width: 40px;
            height: 40px;
            width: 40px;
            background-color: @greyMid2;
            border-radius: 40px;
            text-align: center;
            display: none;
            align-items: center;
            color: @white;
            margin-right: 10px;

            .text {
                flex: 1;
            }

            @media screen and (min-width: @small) {
                display: flex;
            }
        }

        .moderated-container {
            &--info {
                span {
                    display: inline-block;
                    background-color: #f4ebff;
                    padding: 2px 8px;
                    border-radius: 4px;
                    color: #333;
                    width: fit-content;
                    margin-right: 8px;
                }
                margin-top: 5px;
                min-width: 260px;
                img {
                    height: 16px;
                    width: auto;
                    margin-top: -4px;
                }
            }
        }

        .bubble {
            border-radius: 17px 17px 17px 0;
            background-color: @white;
            padding: 12px 24px 12px 16px;
            min-width: 310px;
            font-size: 17px;

            @media screen and (min-width: @small) {
                font-size: 14px;
            }

            .moderated-container {
                &--tag {
                    display: inline-flex;
                    align-items: center;
                    background-color: #f4ebff;
                    padding: 2px 8px;
                    border-radius: 4px;
                    color: #333;
                    font-size: 10px;
                    margin-top: @gutterSpacing-base;
                    img {
                        height: 12px;
                        width: auto;
                        margin-right: 6px;
                    }
                }
            }

            .title {
                color: @greyMid;
                font-size: 15px;
                font-style: italic;
                margin-bottom: 8px;

                &:not(:first-child) {
                    margin-top: 15px;
                }

                @media screen and (min-width: @small) {
                    font-size: 12px;
                }
            }

            .quote {
                border-left: 2px solid rgba(46, 91, 255, 0.08);
                color: @darkGreyBlue;
                padding-left: 10px;
            }

            &.automation {
                display: flex;
                align-items: baseline;
                position: relative;

                .automation-icon {
                    width: 10px;
                    margin-right: 10px;
                }
            }

            &.review {
                background-color: @midGreyBlue;
                color: @white;
            }

            &.shoutout {
                background-color: @brandPurple10;
            }

            .delete-message {
                position: absolute;
                background: @white;
                border-radius: 10px;
                left: -5px;
                top: -5px;
                width: 20px;
                height: 20px;
                cursor: pointer;

                .delete-cross {
                    -webkit-transform: translateX(50%);
                    -moz-transform: translateX(50%);
                    -o-transform: translateX(50%);
                    -ms-transform: translateX(50%);
                    transform: translateX(50%);
                }
            }

            &.note {
                background-color: @yellowMid;
                display: flex;
                align-items: baseline;
                position: relative;

                .note-icon {
                    width: 10px;
                    margin-right: 10px;
                }
            }

            &.case_update {
                display: flex;
                align-items: baseline;
                position: relative;
                background-color: #f4ebff;

                .case-update-icon {
                    margin-right: 10px;
                    align-self: center;
                }
            }

            &.case_assignment {
                display: flex;
                align-items: baseline;
                position: relative;
                background-color: #f4ebff;

                .case-assignment-icon {
                    margin-right: 10px;
                    align-self: center;
                }
            }
        }
    }

    .info {
        color: @grey40;
        font-size: 13px;
        padding-top: 4px;

        @media screen and (min-width: @small) {
            font-size: 10px;
            padding-left: 50px;
        }

        .name {
            text-transform: uppercase;
            letter-spacing: 1px;
            font-weight: 500;
        }

        .dot {
            position: relative;
            top: -3px;
        }

        .dot + .dot {
            display: none; // don't show two dots
        }

        .reply-review {
            color: @blue;
        }

        .undo {
            cursor: pointer;
            color: #2e5bff;
        }

        .eyes {
            font-size: 14px;
            position: relative;
            top: 3px;
        }
    }

    &.outgoing {
        .chat-msg {
            flex-direction: row-reverse;

            .bubble {
                border-radius: 17px 17px 0 17px;

                &.chat {
                    background-color: @blueLight;
                }
            }
        }

        .info {
            text-align: right;
        }
    }
}

.info {
    .delete-message {
        margin-left: 24px;
        color: @blue;
        cursor: pointer;
    }
}
</style>
<style lang="less">
@import '../../styles/layout.less';
@import '../../styles/palette.less';

.chat-msg-container {
    .chat-msg {
        word-break: break-word;

        .bubble {
            > div:not(:last-child) {
                margin-bottom: 16px;
            }

            .quote {
                .dot {
                    width: 8px;
                    height: 8px;
                    border-radius: 8px;

                    &.success {
                        background: @green;
                        display: inline-block;
                        margin-right: 10px;
                    }

                    &.warning {
                        background: @greyMid;
                        display: inline-block;
                        margin-right: 10px;
                    }

                    &.danger {
                        background: @red;
                        display: inline-block;
                        margin-right: 10px;
                    }
                }
                min-height: 20px;
            }
        }
    }

    .cursor {
        cursor: pointer;
    }

    .slide-enter-active {
        transition: all 0.2s ease-out;
    }

    .slide-leave-active {
        transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
    }

    .slide-enter-from,
    .slide-leave-to {
        transform: translatey(-20px);
        opacity: 0;
    }
}
</style>
