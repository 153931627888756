import store from '@/mobile/src/store'
import { chatResponsesRoutes } from '@/pages/chat/responses/router'
import {
    NavigationGuard,
    RouteRecordRaw,
    createRouter,
    createWebHistory,
} from 'vue-router'
import AppV4Home from '@/mobile/src/views/appV4/Home.vue'
import AppV4Feedback from '@/mobile/src/views/appV4/Feedback.vue'
import Teams from '@/mobile/src/views/appV4/Teams.vue'
import Moments from '@/mobile/src/views/appV4/Moments.vue'
import MomentDetail from '@/mobile/src/views/appV4/MomentDetail.vue'
import Feedback from '@/mobile/src/views/Feedback.vue'

// V3 mobile components
import LoggedIn from '@/mobile/src/components/LoggedIn.vue'
import { TabData } from '@/mobile/src/types/tab'
import Activation from '@/mobile/src/views/login/Activation.vue'
import Chat from '@/mobile/src/views/Chat.vue'
import CheckDomainLogin from '@/mobile/src/views/login/CheckDomainLogin.vue'
import FindLogin from '@/mobile/src/views/login/FindLogin.vue'
import Home from '@/mobile/src/views/Home.vue'
import CoachingMetrics from '@/mobile/src/views/insights/CoachingMetrics.vue'
import EngagementMetrics from '@/mobile/src/views/insights/EngagementMetrics.vue'
import CoachingTopic from '@/mobile/src/views/insights/CoachingTopic.vue'
import CoachingTopicFeedback from '@/mobile/src/views/insights/CoachingTopicFeedback.vue'
import GrowthMetrics from '@/mobile/src/views/insights/GrowthMetrics.vue'
import InsightsDetailsModal from '@/mobile/src/views/insights/InsightsDetailsModal.vue'
import InsightsHome from '@/mobile/src/views/insights/InsightsHome.vue'
import StandardLogin from '@/mobile/src/views/login/StandardLogin.vue'
import MagicLinkLogin from '@/mobile/src/views/login/MagicLinkLogin.vue'
import AddNotificationSettings from '@/mobile/src/views/more/AddNotificationSettings.vue'
import More from '@/mobile/src/views/more/More.vue'
import NotificationSettings from '@/mobile/src/views/more/NotificationSettings.vue'
import PickNotificationSettingValue from '@/mobile/src/views/more/PickNotificationSettingValue.vue'
import Settings from '@/mobile/src/views/more/Settings.vue'
import ScorecardHome from '@/mobile/src/views/scorecard/ScorecardHome.vue'
import ScorecardHomeUserMobile from '@/mobile/src/views/scorecard/ScorecardHomeUserMobile.vue'
import TeamScoreboard from '@/mobile/src/views/TeamScoreboard.vue'
import TeamScoreboardModal from '@/mobile/src/views/TeamScoreboardModal.vue'
import Validation from '@/mobile/src/views/login/Validation.vue'
import Mailbox from '@/mobile/src/views/notices/Mailbox.vue'
import MessageComposer from '@/mobile/src/views/notices/MessageComposer.vue'
import NoticeTypePickerScreen from '@/mobile/src/views/notices/NoticeTypePickerScreen.vue'
import RecipientPickerScreen from '@/mobile/src/views/notices/RecipientPickerScreen.vue'
import ScorecardBadgeDetail from '@/pages/scorecard/ScorecardBadgeDetail.vue'
import ScorecardRank from '@/pages/scorecard/ScorecardRank.vue'
import ScorecardRoomToImproveDetail from '@/pages/scorecard/ScorecardRoomToImproveDetail.vue'
import ScorecardRoomToImproveList from '@/pages/scorecard/ScorecardRoomToImproveList.vue'
import ScorecardTrend from '@/pages/scorecard/ScorecardTrend.vue'
import SuggestionActionScreen from '@/pages/suggestions/SuggestionActionScreen.vue'
import SuggestionCreateScreen from '@/pages/suggestions/SuggestionCreateScreen.vue'
import SuggestionScreen from '@/pages/suggestions/SuggestionScreen.vue'
import SuggestionsListScreen from '@/pages/suggestions/SuggestionsListScreen.vue'
import EngagementView from '@/mobile/src/views/insights/EngagementView.vue'
import EngagementTeamThemeDetail from '@/mobile/src/views/insights/EngagementTeamThemeDetail.vue'
import AchievementBadgeDetail from '@/pages/scorecard/AchievementBadgeDetail.vue'
import EngagementTeamSpotlightDetail from '@/pages/insights/EngagementTeamSpotlightDetail.vue'
import ReportCard from '@/mobile/src/views/ReportCard.vue'
import FeedbackCaseManagement from '@/mobile/src/views/appV4/FeedbackCaseManagement.vue'
import FeedbackCustomData from '@/mobile/src/views/appV4/FeedbackCustomData.vue'
import FeedbackDetail from '@/mobile/src/views/appV4/FeedbackDetail.vue'
import EvenMore from '@/mobile/src/views/appV4/EvenMore.vue'
import EvenMoreSettings from '@/mobile/src/views/appV4/EvenMoreSettings.vue'
import ViewAs from '@/mobile/src/views/more/ViewAs.vue'
import FeedbackWorkflow from '@/mobile/src/views/appV4/FeedbackWorkflow.vue'
import FeedbackCaseAssignment from '@/mobile/src/views/appV4/FeedbackCaseAssignment.vue'
import FilterHeader from '@/mobile/src/components/appV4/headers/FilterHeader.vue'
import PageHeader from '@/mobile/src/components/appV4/headers/PageHeader.vue'
import BackHeader from '@/mobile/src/components/appV4/headers/BackHeader.vue'
import Huddles from '@/mobile/src/views/appV4/Huddles.vue'
import ChooseLogin from '@/mobile/src/views/login/ChooseLogin.vue'
import MFASetup from '@/mobile/src/views/appV4/mfa/MFASetup.vue'
import MFAReset from '@/mobile/src/views/appV4/mfa/MFAReset.vue'
import EnforcedMFASetup from '@/mobile/src/views/login/EnforcedMFASetup.vue'
import MFARecoveryCodes from '@/mobile/src/views/login/MFARecoveryCodes.vue'
import MFARecoveryCodeLogin from '@/mobile/src/views/login/MFARecoveryCodeLogin.vue'
import LoginMain from '@/mobile/src/views/login/LoginMain.vue'
import Reels from '@/mobile/src/views/appV4/reels/Reels.vue'
import MFALogin from '@/mobile/src/views/login/MFALogin.vue'

const requireAuthentication: NavigationGuard = (to, from, next) => {
    if (!store.getters.accesstoken) {
        return next({
            name: 'findlogin',
            replace: true,
        })
    }

    if (to.name === 'home') {
        return next({
            name: store.getters.mainNavTabs[0].name,
            replace: true,
        })
    }

    next()
}

const tabRoutes: Array<RouteRecordRaw> = [
    // New mobile routing
    {
        path: 'overview',
        name: 'overview',
        component: AppV4Home,
        meta: {
            group: 'overview',
        },
        beforeEnter: (to, from, next) => {
            if (
                !(store.getters.mainNavTabs as Array<TabData>).find(
                    (tab) => tab.name === 'overview'
                )
            ) {
                next({
                    name: 'home',
                })
            }

            next()
        },
    },
    {
        path: 'huddles',
        name: 'huddles',
        components: { default: Huddles },
        meta: {
            group: 'huddles',
            transitionDepth: 1,
        },
    },
    {
        path: 'overview-user/:extraFilter?',
        name: 'overview-user',
        component: AppV4Home,
        beforeEnter: (to, from, next) => {
            next()
        },
        meta: {
            group: 'teams',
        },
    },
    {
        path: 'teams',
        name: 'teams',
        component: Teams,
        beforeEnter: (to, from, next) => {
            if (
                !(store.getters.mainNavTabs as Array<TabData>).find(
                    (tab) => tab.name === 'teams'
                )
            ) {
                next({
                    name: 'home',
                })
            }

            next()
        },
        meta: {
            group: 'teams',
        },
    },
    {
        path: 'inbox',
        name: 'inbox',
        components: { default: Moments, header: PageHeader },
        meta: {
            group: 'inbox',
            headerTitle: 'inbox',
            transitionDepth: 0,
        },
    },
    {
        path: 'inbox/detail/:momentid?',
        name: 'momentdetails',
        components: { default: MomentDetail, header: BackHeader },
        meta: {
            group: 'inbox',
            headerTitle: 'inbox',
            transitionDepth: 1,
        },
        props: {
            default: (route) => ({ momentid: route.params.momentid }),
        },
    },
    {
        path: 'moments/feedback/detail/:feedbackid?',
        name: 'momentsFeedbackDetail',
        components: { default: FeedbackDetail, header: BackHeader },
        meta: {
            group: 'moments',
            headerTitle: 'feedback',
            transitionDepth: 1,
        },
        props: {
            default: (route) => ({ feedbackid: route.params.feedbackid }),
        },
    },
    {
        path: 'evenmore',
        name: 'evenmore',
        components: { default: EvenMore, header: PageHeader },
        meta: {
            group: 'evenmore',
            headerTitle: 'More',
        },
    },
    {
        path: 'evenmore/settings',
        name: 'evenMoreSettings',
        components: { default: EvenMoreSettings, header: BackHeader },
        meta: {
            group: 'evenmore',
            headerTitle: 'Settings',
        },
    },

    {
        path: '/mfa-setup',
        name: 'mfa-setup',
        components: { default: MFASetup, header: BackHeader },
        meta: {
            group: 'evenmore',
            headerTitle: 'MFA Setup',
        },
    },
    {
        path: '/mfa-reset',
        name: 'mfa-reset',
        components: { default: MFAReset, header: BackHeader },
        meta: {
            group: 'evenmore',
            headerTitle: 'MFA Setup',
        },
    },
    {
        path: 'viewas',
        name: 'viewAs',
        components: { default: ViewAs, header: BackHeader },
        meta: {
            group: 'evenmore',
            headerTitle: 'Switch User',
        },
    },
    {
        path: 'topic-feedback',
        name: 'topicfeedback',
        components: { default: AppV4Feedback, header: BackHeader },
        meta: {
            group: 'myfeedback',
            headerTitle: 'feedback',
        },
    },
    {
        path: 'myfeedback',
        name: 'myfeedback',
        components: { default: AppV4Feedback, header: FilterHeader },
        meta: {
            group: 'myfeedback',
            headerTitle: 'feedback',
            transitionDepth: 0,
        },
    },
    {
        path: 'feedback/detail/:feedbackid?',
        name: 'feedbackdetail',
        components: { default: FeedbackDetail, header: BackHeader },
        meta: {
            group: 'myfeedback',
            headerTitle: 'feedback',
            transitionDepth: 1,
        },
        props: {
            default: (route) => ({ feedbackid: route.params.feedbackid }),
        },
    },
    {
        path: 'feedback/workflow/:feedbackid?',
        name: 'feedbackworkflow',
        components: { default: FeedbackWorkflow, header: BackHeader },
        meta: {
            group: 'myfeedback',
            headerTitle: 'Workflows',
            transitionDepth: 3,
        },
        props: {
            default: (route) => ({ feedbackid: route.params.feedbackid }),
        },
    },
    {
        path: 'feedback/casemanagement/:feedbackid?',
        name: 'feedbackcasemanagement',
        components: { default: FeedbackCaseManagement, header: BackHeader },
        meta: {
            group: 'myfeedback',
            headerTitle: 'Case Management',
            transitionDepth: 3,
        },
        beforeEnter: (to, from, next) => {
            if (to?.meta && store.getters.chatCustomData) {
                to.meta.headerTitle =
                    Number(store.getters.chatCustomData.case_closed) === 1
                        ? 'Closed Case'
                        : 'Open Case'
            }

            next()
        },
        props: {
            default: (route) => ({ feedbackid: route.params.feedbackid }),
        },
    },
    {
        path: 'feedback/customdata/:feedbackid?',
        name: 'feedbackcustomdata',
        components: { default: FeedbackCustomData, header: BackHeader },
        meta: {
            group: 'myfeedback',
            headerTitle: 'Custom Data',
            transitionDepth: 3,
        },
        props: {
            default: (route) => ({ feedbackid: route.params.feedbackid }),
        },
    },
    {
        path: 'feedback/case-assignment/:feedbackid?',
        name: 'feedbackcaseassignment',
        components: { default: FeedbackCaseAssignment, header: BackHeader },
        meta: {
            group: 'myfeedback',
            headerTitle: 'Assign To',
            transitionDepth: 3,
        },
        props: {
            default: (route) => ({ feedbackid: route.params.feedbackid }),
        },
    },
    // V3 mobile routing
    {
        path: 'scorecard',
        name: 'scorecardHome',
        component: ScorecardHome,
        beforeEnter: (to, from, next) => {
            if (
                !(store.getters.mainNavTabs as Array<TabData>).find(
                    (tab) => tab.name === 'scorecardHome'
                )
            ) {
                next({
                    name: 'home',
                })
            }

            next()
        },
    },
    {
        path: 'scorecard/user/:teamMemberUserId',
        name: 'scorecardHomeUser',
        component: ScorecardHomeUserMobile,
        props: true,
        meta: { tab: 'scorecardHome' },
        beforeEnter: (to, from, next) => {
            if (
                !(store.getters.mainNavTabs as Array<TabData>).find(
                    (tab) =>
                        tab.name === 'insightsHome' ||
                        tab.name === 'scorecardHome'
                )
            ) {
                next({
                    name: 'home',
                })
            }

            next()
        },
    },
    {
        path: 'scorecard/trend',
        name: 'scorecardTrend',
        component: ScorecardTrend,
        meta: { tab: 'scorecardHome' },
        props: true,
    },
    {
        path: 'scorecard/trend/user/:teamMemberUserId',
        name: 'scorecardTrendUser',
        component: ScorecardTrend,
        meta: { tab: 'scorecardHome' },
        props: true,
    },
    {
        path: 'scorecard/rank',
        name: 'scorecardRank',
        component: ScorecardRank,
        meta: { tab: 'scorecardHome' },
    },
    {
        path: 'scorecard/rank/user/:teamMemberUserId',
        name: 'scorecardRankUser',
        component: ScorecardRank,
        meta: { tab: 'scorecardHome' },
        props: true,
    },
    {
        path: 'scorecard/badge/:value',
        name: 'scorecardBadge',
        component: ScorecardBadgeDetail,
        meta: { tab: 'scorecardHome' },
        props: true,
    },
    {
        path: 'scorecard/badge/:value/user/:teamMemberUserId',
        name: 'scorecardBadgeUser',
        component: ScorecardBadgeDetail,
        meta: { tab: 'scorecardHome' },
        props: true,
    },
    {
        path: 'scorecard/achievement/:value',
        name: 'achievementBadge',
        component: AchievementBadgeDetail,
        props: true,
        meta: { tab: 'scorecardHome' },
    },
    {
        path: 'scorecard/achievement/:value/user/:teamMemberUserId',
        name: 'achievementBadgeUser',
        component: AchievementBadgeDetail,
        props: true,
        meta: { tab: 'scorecardHome' },
    },
    {
        path: 'scorecard/room_to_improve',
        name: 'scorecardRoomToImproveList',
        component: ScorecardRoomToImproveList,
        meta: { tab: 'scorecardHome' },
    },
    {
        path: 'scorecard/room_to_improve/user/:teamMemberUserId',
        name: 'scorecardRoomToImproveListUser',
        component: ScorecardRoomToImproveList,
        meta: { tab: 'scorecardHome' },
    },
    {
        path: 'scorecard/room_to_improve/:value',
        name: 'scorecardRoomToImproveDetail',
        component: ScorecardRoomToImproveDetail,
        props: true,
        meta: { tab: 'scorecardHome' },
    },
    {
        path: 'scorecard/room_to_improve/:value/user/:teamMemberUserId',
        name: 'scorecardRoomToImproveDetailUser',
        component: ScorecardRoomToImproveDetail,
        props: true,
        meta: { tab: 'scorecardHome' },
    },
    {
        path: 'insights',
        name: 'insightsHome',
        component: InsightsHome,
        beforeEnter: (to, from, next) => {
            if (
                !(store.getters.mainNavTabs as Array<TabData>).find(
                    (tab) => tab.name === 'insightsHome'
                )
            ) {
                next({
                    name: 'home',
                })
            }

            next()
        },
    },
    {
        path: 'insights/growth',
        name: 'insightsGrowthMetrics',
        component: GrowthMetrics,
        meta: { tab: 'insightsHome' },
    },
    {
        path: 'insights/coaching',
        name: 'InsightsCoachingMetrics',
        component: CoachingMetrics,
        meta: { tab: 'insightsHome' },
    },
    {
        path: 'insights/engagement',
        name: 'InsightsEngagementMetrics',
        component: EngagementMetrics,
        meta: { tab: 'insightsHome' },
    },
    {
        path: 'insights/engagement',
        component: EngagementView,
        meta: { tab: 'insightsHome' },
        props: true,
        children: [
            {
                path: 'spotlight/:teamMemberUserId',
                name: 'InsightsEngagementTeamThemeSpotlightDetail',
                component: EngagementTeamSpotlightDetail,
                props: true,
            },
            {
                path: 'theme/:themeId',
                name: 'InsightsEngagementTeamThemeDetail',
                component: EngagementTeamThemeDetail,
                props: true,
            },
        ],
    },
    {
        path: 'insights/coaching/topics/:topic',
        name: 'InsightsCoachingTopic',
        component: CoachingTopic,
        props: true,
        meta: { tab: 'insightsHome' },
    },
    {
        path: 'insights/coaching/topics/:topic/:feedbackType',
        name: 'InsightsCoachingTopicFeedback',
        component: CoachingTopicFeedback,
        props: true,
        meta: { tab: 'insightsHome' },
    },
    {
        path: 'notices',
        redirect: { name: 'notices' },
    },
    {
        path: 'notices/:mailbox(sent|received|scheduled)?/:messageType?/:messageId?',
        name: 'notices',
        props: (route) => ({
            mailbox: route.params.mailbox || 'received',
            messageType: route.params.messageType,
            messageId: route.params.messageId,
        }),
        component: Mailbox,
    },
    {
        path: '/:mailbox/editor/compose/:preselectedUserId?',
        name: 'notices-editor-compose',
        props: true,
        component: MessageComposer,
        meta: {
            tab: 'notices',
        },
    },
    {
        path: '/:mailbox/editor/compose/pick-recipients',
        name: 'notices-editor-compose-pick-recipients',
        component: RecipientPickerScreen,
        props: true,
        meta: {
            tab: 'notices',
        },
    },
    {
        path: '/:mailbox/pick-type',
        name: 'notices-pick-type',
        component: NoticeTypePickerScreen,
        props: true,
        meta: {
            tab: 'notices',
        },
    },
    {
        path: 'notices/user/:preselectedUserId/pick-type',
        name: 'notices-pick-type-user',
        component: NoticeTypePickerScreen,
        props: true,
        meta: {
            tab: 'notices',
        },
    },
    {
        path: '/:mailbox/editor/compose/pick-type',
        name: 'notices-editor-compose-pick-type',
        component: NoticeTypePickerScreen,
        props: true,
        meta: {
            tab: 'notices',
        },
    },
    {
        path: 'feedback',
        name: 'feedback',
        component: Feedback,
        meta: {
            tab: 'feedback',
        },
    },
    {
        path: '/chat/responses',
        name: 'chat',
        component: Chat,
        children: [...chatResponsesRoutes],
    },
    {
        path: 'more',
        name: 'more',
        component: More,
    },
    {
        path: '/leaderboards',
        name: 'leaderboards',
        component: () => import('../views/leaderboards/Leaderboards.vue'),
        meta: { tab: 'more' },
    },
    {
        path: 'more/teamscoreboard',
        name: 'teamscoreboard',
        component: TeamScoreboard,
        meta: {
            tab: 'more',
        },
    },
    {
        path: '/suggestions/:mode?', // actioned | popular | recent
        component: SuggestionsListScreen,
        props: (route) => ({ mode: route.params.mode || 'recent' }),
        name: 'suggestions',
        meta: {
            tab: 'suggestions',
        },
    },
    {
        path: '/suggestions/suggestion/create',
        component: SuggestionCreateScreen,
        name: 'suggestionsCreate',
        meta: {
            tab: 'suggestions',
        },
    },
    {
        path: '/suggestions/suggestion/:id/action/:action',
        component: SuggestionActionScreen,
        props: true,
        name: 'suggestionsAction',
        meta: {
            tab: 'suggestions',
        },
    },
    {
        path: '/suggestions/suggestion/:id',
        component: SuggestionScreen,
        props: true,
        name: 'suggestionsDetail',
        meta: {
            tab: 'suggestions',
        },
    },
    {
        path: '/more/settings',
        name: 'settings',
        component: Settings,
        meta: { tab: 'more' },
    },
    {
        path: '/more/settings/notifications',
        name: 'notificationSettings',
        component: NotificationSettings,
        meta: { tab: 'more' },
    },
    {
        path: '/more/settings/notifications/add',
        name: 'addNotificationSettings',
        component: AddNotificationSettings,
        meta: { tab: 'more' },
    },
    {
        path: '/more/settings/notifications/add/:name',
        name: 'pickNotificationSettingValue',
        props: true,
        component: PickNotificationSettingValue,
        meta: { tab: 'more' },
    },
]

const loggedInRoutes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'home', // home page holds all pages with tabs sitting at bottom
        component: Home,
        beforeEnter: requireAuthentication,
        children: tabRoutes,
    },
    {
        // Do we still need this route???
        path: '/reportcard/:month?',
        name: 'reportCard',
        props: (route) => {
            return {
                ...route.params,
                ...{
                    month: Number.parseInt(route.params.month.toString()),
                },
            }
        },
        component: ReportCard,
        beforeEnter: requireAuthentication,
        meta: {
            transition: 'modal',
            tab: 'reportCard',
        },
    },
    {
        path: '/insights/detail',
        name: 'insightsDetail',
        component: InsightsDetailsModal,
        meta: {
            transition: 'modal',
        },
    },
    {
        path: '/more/teamscoreboard/detail',
        name: 'teamscoreboardDetail',
        component: TeamScoreboardModal,
        meta: {
            transition: 'modal',
        },
    },
    {
        path: '/reels',
        name: 'reels',
        component: Reels,
    },
]

const loginRoutes: Array<RouteRecordRaw> = [
    {
        path: '/login', // default login with domain, email and password
        name: 'login',
        component: StandardLogin,
        props: ({ query }) => ({
            domain: query.domain,
            disableEmailLogin: parseInt(query.disableEmailLogin as string, 10),
            samlSsoName: query.samlSsoName,
            samlSsoUrl: query.samlSsoUrl,
        }),
    },
    {
        path: '/findlogin', // use email to find accounts and send invitation links
        name: 'findlogin',
        component: FindLogin,
    },
    {
        path: '/check-domain', // check current domain is valid or not
        name: 'check-domain',
        component: CheckDomainLogin,
    },
    {
        path: '/choose-login', // check current domain is valid or not
        name: 'choose-login',
        component: ChooseLogin,
        meta: {
            headerTitle: 'Login',
            hasLogo: false,
            center: false,
        },
    },
    {
        path: '/magiclogin', // when click on invitation link, this component processes it
        name: 'magiclogin',
        component: MagicLinkLogin,
        props: (route) => ({ url: route.query.url }),
    },
    {
        path: '/activation',
        name: 'activation',
        component: Activation,
        props: (route) => ({ url: route.query.url }),
    },
    {
        path: '/validation',
        name: 'validation',
        component: Validation,
        props: (route) => ({ url: route.query.url }),
    },
    {
        path: '/mfa-login',
        name: 'mfa-login',
        component: MFALogin,
        meta: {
            hasLogo: false,
        },
    },
    {
        path: '/enforced-mfa-setup',
        name: 'enforced-mfa-setup',
        component: EnforcedMFASetup,
        meta: {
            headerTitle: 'MFA Setup',
            hasLogo: false,
            center: false,
        },
    },
    {
        path: '/recovery-codes-setup/:fromMfaSetup',
        name: 'recovery-codes-setup',
        component: MFARecoveryCodes,
        meta: {
            headerTitle: 'MFA - Recovery Codes Setup',
            hasLogo: false,
            center: false,
        },
    },
    {
        path: '/mfa-recovery-code-login',
        name: 'mfa-recovery-code-login',
        component: MFARecoveryCodeLogin,
        meta: {
            hasLogo: false,
        },
    },
]

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        component: LoggedIn,
        children: loggedInRoutes,
    },
    // individual pages that block whole screen
    {
        path: '/l',
        component: LoginMain,
        children: loginRoutes,
        meta: {
            hasLogo: true,
            center: true,
        },
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: '/',
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
})

const waitForStorageToBeReady: NavigationGuard = async (to, from, next) => {
    await (store as any).restored
    next()
}

router.beforeEach(waitForStorageToBeReady)

export default router
