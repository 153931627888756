import { processDeepLink } from '@/mobile/src/services/deeplink'
import store from '@/mobile/src/store'
import { GoogleAuthResult, GoogleSignInResult } from '@/mobile/src/types/auth'
import { rootDomain } from '@/mobile/src/utils/misc'
import { registerPushNotificationListener } from '@/mobile/src/utils/pushnotification'
import { Capacitor } from '@capacitor/core'
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth'
import { Browser } from '@capacitor/browser'
import {
    Camera,
    ImageOptions,
    CameraResultType,
    CameraSource,
} from '@capacitor/camera'
import {
    App,
    AppState,
    BackButtonListenerEvent,
    URLOpenListenerEvent,
} from '@capacitor/app'
import {
    ScreenOrientation,
    ScreenOrientationResult,
} from '@capacitor/screen-orientation'
import { Device } from '@capacitor/device'
import { Keyboard, KeyboardInfo } from '@capacitor/keyboard'
import { Network } from '@capacitor/network'
import { SingleSignOn } from '@teamhive/capacitor-single-signon'
import { FirebaseDynamicLinks } from '@pantrist/capacitor-firebase-dynamic-links'
import { AppLauncher } from '@capacitor/app-launcher'
import { PushNotifications } from '@capacitor/push-notifications'
import { deviceCheck } from '@/mobile/src/utils/device'
import { Deploy } from 'cordova-plugin-ionic'
import { dataURItoBlob } from '@/utils/image'
import { pendoTrackEvent } from '@/utils/pendo'
import { NoticeType } from '@/store/modules/notices'

declare global {
    interface Window {
        globalAndroidBackButtonHandler?: () => void
    }
}

export interface AskNicelyAppInfo {
    text?: string
    version?: string
    build?: string
}

export async function samlLogin(samlRoute: string, domain: string) {
    try {
        const response = await SingleSignOn.authenticate({
            url: `https://${domain}.${rootDomain}${samlRoute}`,
            customScheme: 'asknicely',
        })

        return response.url.split('?')[1]
    } catch (error) {
        alert(`Sorry, we couldn't log you in this time`)
        return ''
    }
}

export async function getPlatform() {
    const info = await Device.getInfo()
    return info.platform
}

export async function getDeviceId() {
    const info = await Device.getId()
    return info.identifier
}

export async function launchMailApp() {
    const platform = await getPlatform()
    if (platform === 'ios') {
        await AppLauncher.openUrl({ url: 'message://' })
    } else if (platform === 'android') {
        // how to get url? I found this link
        // https://medium.com/javascript-in-plain-english/opening-another-app-from-your-ionic-5-app-becf8c098d0e
        await AppLauncher.openUrl({ url: 'com.google.android.gm' })
    } else {
        alert('only support in app')
    }
}

export async function initializeApp() {
    // check whether we have an initial network connection or not
    const status = await Network.getStatus()
    store.commit('setNetworkStatus', status.connected)
    // gather app info and store in store for further usage
    await gatherDeviceInfo()
    await updatePushNotificationPermissionsInStore()
    // dynamic link url handled here
    // translate asknicely.page.link/xxx to normal url
    try {
        await FirebaseDynamicLinks.addListener(
            'deepLinkOpen',
            async (data: { url: string }) => {
                await processDeepLink(data.url)
            }
        )
    } catch (e) {
        // ignore, suppress warning in browser for web not implemented for the plugin
    }

    try {
        const platform = await getPlatform()
        if (platform !== 'web') {
            const orientation = await ScreenOrientation.orientation()
            handleNavBarPosForOrientation(orientation)

            await ScreenOrientation.addListener(
                'screenOrientationChange',
                (orientation: ScreenOrientationResult) => {
                    handleNavBarPosForOrientation(orientation)
                }
            )
        } else {
            store.commit('setNavBarPosition', 'horizontal')
        }
    } catch (e) {
        store.commit('setNavBarPosition', 'horizontal')
    }

    // register deep link url
    App.addListener('appUrlOpen', async (data: URLOpenListenerEvent) => {
        const { url } = data
        if (/asknicely(dev)?\.page\.link/.test(url)) {
            return // firebase dynamic link handled differently
        }
        await processDeepLink(url)
    })

    // app is now active again so let's refresh user details/permissions
    App.addListener('appStateChange', async (state: AppState) => {
        if (!state.isActive || !store.getters.hasNetworkConnection) {
            // Stop all polling at global level as app is not active
            await store.dispatch('dispatchStopPolling')
            return
        }

        // On wakeup, we want to get any updated details, start unseen moment polling and notices
        await store.dispatch('dispatchStoreLogin')
        if (store.getters.hasNoticeTab) {
            if (store.getters.userHasMlp) {
                // App V4: Restrict to Message, Shoutout, and Coaching
                await store.dispatch('loadMessagesReceived', [
                    NoticeType.General,
                    NoticeType.Shoutout,
                    NoticeType.Coaching,
                ])
            } else {
                await store.dispatch('loadMessagesReceived')
            }
        }

        await store.dispatch('fillOfflineCache')
        await store.dispatch('loadDailyEngagementQuestions')

        await deviceCheck()
    })

    Network.addListener('networkStatusChange', (status) => {
        if (!status.connected) {
            try {
                window.stop() // Cancel all pending network requests
                store.dispatch('resetLastDataCached')
            } catch (e) {
                /* empty */
            }
        }
        store.commit('setNetworkStatus', status.connected)
    })

    // android back button handle
    App.addListener('backButton', (data: BackButtonListenerEvent) => {
        // if any global handler registered, use it, otherwise fallback to normal back behaviour
        if (window.globalAndroidBackButtonHandler) {
            window.globalAndroidBackButtonHandler()
        } else {
            history.back()
        }
    })

    await registerPushNotificationListener()

    if (Capacitor.isPluginAvailable('Keyboard')) {
        Keyboard.addListener('keyboardWillShow', (info: KeyboardInfo) => {
            store.dispatch('hideBottomTabs')
        })

        Keyboard.addListener('keyboardWillHide', () => {
            store.dispatch('showBottomTabs')
        })
    }
}

export async function gatherDeviceInfo() {
    // gather device info
    const deviceInfo = await Device.getInfo()
    let appInfo: AskNicelyAppInfo = {}

    if (deviceInfo.platform !== 'web') {
        appInfo = await App.getInfo()

        try {
            const appVersion = appInfo.version?.toString() ?? 'N/A' // Default to N/A if no app version
            await checkForAppflowLiveUpdate(appVersion)
        } catch (error) {
            // If live update has failed, handle it silently without throwing error & causing the user to get stuck in splash screen
        }
    }
    store.commit('setDeviceInfo', { ...deviceInfo, ...appInfo })
}

function handleNavBarPosForOrientation(orientation: ScreenOrientationResult) {
    if (orientation.type?.includes('landscape')) {
        const width = window.screen.width
        const height = window.screen.height

        const minTabletSize = 600 // Minimum size in pixels to consider a device a tablet
        if (Math.min(width, height) >= minTabletSize) {
            store.commit('setNavBarPosition', 'vertical')
        }
        return
    }

    store.commit('setNavBarPosition', 'horizontal')
}

async function checkForAppflowLiveUpdate(appVersion: string) {
    // Switch live update channel based on app version
    const liveUpdateChannel =
        appVersion === process.env.INTERNAL_TESTING_VERSION
            ? 'Internal'
            : 'Production'
    await Deploy.configure({ channel: liveUpdateChannel })

    // Check for live update available
    let updateAvailable = false
    await Deploy.checkForUpdate().then((update) => {
        updateAvailable = update.available
    })

    // Do manual download if update is available
    if (updateAvailable) {
        await Deploy.downloadUpdate().catch(() => {
            throw new Error('Appflow live update download failed')
        })
        await Deploy.extractUpdate().catch(() => {
            throw new Error('Appflow live update installation failed')
        })
        await Deploy.reloadApp().catch(() => {
            throw new Error('Appflow live update reload failed')
        })
    }
}

export async function updatePushNotificationPermissionsInStore() {
    // gather push notification permission
    if (Capacitor.isPluginAvailable('PushNotifications')) {
        const pushPermission = (await PushNotifications.checkPermissions())
            .receive
        store.commit('setPushPermission', pushPermission)
    }
}

export async function googleSignIn(): Promise<GoogleSignInResult> {
    try {
        GoogleAuth.initialize()
        const result = (await GoogleAuth.signIn()) as GoogleAuthResult
        const googleUserAuthId = result.authentication.idToken

        return { success: true, googleUserAuthId }
    } catch (e) {
        return {
            success: false,
            msg: `Something went wrong, we couldn't log you in this time`,
        }
    }
}

export async function initWebGoogleSignIn(callbackFn?: Function) {
    const clientId = document
        .querySelector('meta[name="google-signin-client_id"]')
        ?.getAttribute('content')
    window.google.accounts.id.initialize({
        client_id: clientId,
        use_fedcm_for_prompt: true,
        callback: (response: any) => {
            if (
                callbackFn &&
                response.credential &&
                response.credential.length
            ) {
                callbackFn(response.credential)
            } else {
                alert(`Something went wrong, we couldn't log you in this time`)
            }
        },
    })
    window.google.accounts.id.renderButton(
        document.getElementById('web-mobile-google-sign-in-button'),
        { theme: 'outline', size: 'large' }
    )
}

export async function getAppVersion() {
    const appInfo = await getAskNicelyAppInfo()
    return appInfo.text ?? ''
}

export async function getAskNicelyAppInfo() {
    const deviceInfo = await Device.getInfo()
    const appInfo: AskNicelyAppInfo = {
        text: 'N/A in web',
        version: '',
        build: '',
    }
    if (deviceInfo.platform !== 'web') {
        const info = await App.getInfo()
        appInfo.version = info.version
        appInfo.build = info.build
        appInfo.text = `${info.version}.${info.build}`
    }
    return appInfo
}

// get photo from camera
export async function getPhoto(
    options: ImageOptions = {
        quality: 90,
        width: 110,
        height: 110,
        resultType: CameraResultType.DataUrl,
    }
) {
    if (!Capacitor.isPluginAvailable('Camera')) {
        return false
    }

    options.allowEditing = (await getPlatform()) === 'ios'

    try {
        return await Camera.getPhoto(options)
    } catch (e) {
        return false
    }
}

// Get photo from image gallery for Moments message composer
export async function getPhotoForMomentsComposer(): Promise<{
    success: boolean
    message?: string
    file?: File
}> {
    const options: ImageOptions = {
        quality: 100, // Highest available quality out of 100
        resultType: CameraResultType.DataUrl,
        source: CameraSource.Photos, // Cannot take a picture, only select from gallery
        webUseInput: true, // Don't use PWA element experience on web - fall back to file input
    }

    try {
        const photo = await Camera.getPhoto(options)

        if (!photo || !photo.dataUrl) {
            return { success: false }
        }

        // Check file format
        const acceptedFileFormats = ['jpeg', 'jpg', 'png', 'gif', 'webp', 'svg']
        if (!acceptedFileFormats.includes(photo.format)) {
            pendoTrackEvent(
                `moments-composer-image-wrong-format-${photo.format}`
            )
            return {
                success: false,
                message:
                    'Image format is unsupported. Supported file formats are: JPG, JPEG, PNG, WEBP and SVG',
            }
        }

        // Get image blob and create a JPEG file
        const blob = dataURItoBlob(photo.dataUrl)
        const file = new File([blob as any], `moment.${photo.format}`, {
            type: `image/${photo.format}`,
        })

        // Don't allow files over 5 megabytes
        if (file.size > 5 * 1024 * 1024) {
            pendoTrackEvent('moments-composer-image-too-large')
            return {
                success: false,
                message: 'Image is too large. Max size is 5MB',
            }
        }

        return { success: true, file }
    } catch (error: any) {
        const cancelMessages = [
            'No image picked', // Android cancel message
            'User cancelled photos app', // iOS cancel message
        ]
        if (cancelMessages.includes(error.message)) {
            return { success: false } // Don't show an error message if the user cancels selection
        }
        return { success: false, message: error.message }
    }
}

export function openBrowser(url: string) {
    return Browser.open({ url })
}
