<template>
    <div :class="['feedback-base-card', cardType]" @click="viewDetail">
        <div
            v-if="showStar || showTitle"
            :class="['card-header', getHighlightClass()]"
        >
            <Typography v-if="showTitle" variant="overline" class="title"><slot name="title" /></Typography>
            <Typography variant="overline" class="right-title"><slot name="rightTitle" /></Typography>
            <div
                v-if="showStar && hasNetworkConnection"
                class="star-container"
                @click.stop="onToggleStarred"
            >
                <FontAwesomeIcon :icon="faStarOutline" class="star" size="lg" />
                <Transition>
                    <FontAwesomeIcon
                        :icon="faStar"
                        :class="['star solid', { show: isStarred }]"
                        size="lg"
                    />
                </Transition>
            </div>
        </div>
        <div v-if="$slots.image" class="card-image">
            <slot name="image" />
        </div>

        <div class="card-content">
            <div class="first-line">
                <div v-if="$slots.icon" class="icon"><slot name="icon" /></div>
                <div class="card-main-content">
                    <div class="line-author-view-moderate-comment">
                        <Typography
                            v-if="author"
                            variant="overline"
                            class="card-author"
                            >{{ author }}</Typography>

                        <div
                            v-if="isAdmin && hasModeratedComment"
                            class="btn-view-moderate-comment"
                        >
                            <img :src="magicWand" />
                            <a @click="toggleComment">{{ viewCommentText }}</a>
                        </div>
                    </div>
                    <p v-if="hasAiQuestion" class="card-label">
                        {{ firstAiQuestion.question_text }}
                    </p>

                    <div class="card-text">
                        <transition
                            v-if="isAdmin && hasModeratedComment"
                            name="slide"
                        >
                            <Typography v-if="showText" variant="body2">
                                <TextClamp
                                    is-html
                                    :text="cardText"
                                    :max-lines="detailedView ? 0 : 2"
                                    :text-italic="textItalic"
                                />
                            </Typography>
                        </transition>

                        <Typography v-else variant="body2">
                            <TextClamp
                                is-html
                                :text="cardText"
                                :max-lines="detailedView ? 0 : 2"
                                :text-italic="textItalic"
                            />
                        </Typography>
                        <div class="icons-container">
                            <div
                                v-if="commentCount > 0"
                                :class="['comment-number', commentCountClass]"
                            >
                                {{ commentCount }}
                            </div>
                            <div v-if="$slots.extraIcon">
                                <slot name="extraIcon" />
                            </div>
                        </div>
                    </div>

                    <div
                        v-if="originalComment"
                        class="moderated-container--tag"
                    >
                        <img :src="magicWand" />
                        Moderated by NiceAI
                    </div>

                    <div v-if="!hasExtraDetails" class="card-details">
                        <Typography
                            v-if="timestamp"
                            variant="overline"
                            class="timestamp"
                        >
                            <span>{{ getRelativeDate(timestamp) }}</span>
                            <span v-if="showUndo" @click="$emit('undoChat')">
                                <span class="undo-text">- Undo</span>
                                <svg
                                    class="countdown"
                                    :width="circleSize"
                                    :height="circleSize"
                                >
                                    <circle
                                        class="countdown-circle"
                                        cx="50%"
                                        cy="50%"
                                        r="25%"
                                        :style="circleStyle"
                                    />
                                </svg>
                            </span>
                        </Typography>
                    </div>
                </div>
            </div>

            <div class="other-lines">
                <div class="card-extra-details">
                    <slot name="extraDetails" />
                </div>

                <div v-if="hasExtraDetails" class="card-details">
                    <Typography
                        v-if="timestamp"
                        variant="overline"
                        class="timestamp"
                    >
                        <span>{{ getRelativeDate(timestamp) }}</span>
                        <span v-if="showUndo" @click="$emit('undoChat')">
                            <span class="undo-text">- Undo</span>
                            <svg
                                class="countdown"
                                :width="circleSize"
                                :height="circleSize"
                            >
                                <circle
                                    class="countdown-circle"
                                    cx="50%"
                                    cy="50%"
                                    r="25%"
                                    :style="circleStyle"
                                />
                            </svg>
                        </span>
                    </Typography>
                </div>
            </div>
        </div>

        <EmojiReactions
            v-if="emojiView && hasNetworkConnection"
            :reactions="emojiReactions"
            @onReact="onReact"
        />

        <div v-if="doubleCard" class="double-card">
            <div class="second-title">
                <Typography variant="overline"><slot name="secondTitle" /></Typography>
                <Typography variant="overline" class="right-title"><slot name="secondRightTitle" /></Typography>
            </div>

            <div class="card-content">
                <div class="first-line">
                    <div class="icon">
                        <slot v-if="$slots.secondIcon" name="secondIcon" />
                    </div>
                    <div class="card-main-content">
                        <Typography variant="overline" class="card-author"><slot name="secondAuthor" /></Typography>
                        <Typography variant="body2" class="card-text">
                            <slot name="secondText" />
                        </Typography>

                        <div class="card-details">
                            <Typography variant="overline" class="timestamp">
                                <slot name="secondTimestamp" />
                            </Typography>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { useSlots } from 'vue'
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { Getter } from 'vuex-facing-decorator'
import { FontAwesomeIcon } from 'fontawesome/vue-fontawesome'
import { faStar } from 'fontawesome/free-solid-svg-icons'
import { faStar as faStarOutline } from 'fontawesome/free-regular-svg-icons'
import { relativeDate, timeSince } from '@/utils/time'
import Typography from '@/components/Rain/Typography/Typography.vue'
import EmojiReactions from '@/mobile/src/components/appV4/EmojiReactions.vue'
import TextClamp from '@/components/TextClamp.vue'
import { EmojiReactionDetails, IOnReact } from '@/entities'
import { Permissions, UserDetails } from '@/mobile/src/types/auth'
import MagicWand from '@/assets/img/Icons/magic-wand.svg'

@Component({
    components: {
        TextClamp,
        EmojiReactions,
        Typography,
        FontAwesomeIcon,
    },
    emits: [
        'undoChat',
        'toggleStarred',
        'viewDetail',
        'onReact',
        'toggleOriginalComment',
    ],
})
export default class FeedbackBaseCard extends Vue {
    @Prop({ type: String, required: false, default: '' }) cardType!: string
    @Prop({ type: Number, required: false }) timestamp!: number
    @Prop({ type: String, required: false }) author!: string
    @Prop({ type: String, required: false }) text!: string
    @Prop({ type: String, required: false })
    originalComment!: string
    @Prop({ type: String, required: false })
    hasModeratedComment!: boolean
    @Prop({ type: Boolean, required: false, default: false })
    textItalic!: boolean
    @Prop({ type: Boolean, required: false, default: false })
    isStarred!: boolean
    @Prop({ type: Boolean, required: false, default: false })
    highlight!: boolean
    @Prop({ type: Boolean, required: false, default: false })
    showStar!: boolean
    @Prop({ type: Boolean, required: false, default: true })
    showTitle!: boolean
    @Prop({ type: Boolean, required: false, default: false })
    hasAiQuestion!: boolean
    @Prop({ type: String, required: false })
    firstAiQuestion!: any
    @Prop({ type: Boolean, required: false, default: false })
    doubleCard!: boolean
    @Prop({ type: Number, required: false }) commentCount!: number
    @Prop({ type: String, default: 'positive' }) commentCountClass?: string
    @Prop({ type: Boolean, required: false, default: false })
    detailedView!: boolean
    @Prop({ type: Boolean, required: false, default: false })
    emojiView!: boolean
    @Prop({ type: Boolean, required: false, default: false })
    showUndo!: boolean
    @Prop({ type: Number, required: false, default: 0 })
    undoTimeLeft!: number
    @Prop({ type: Array, required: false, default: () => [] })
    emojiReactions!: EmojiReactionDetails[]

    @Getter readonly hasNetworkConnection
    @Getter public details!: UserDetails

    public faStar = faStar
    public faStarOutline = faStarOutline
    public circleSize = 12

    public slots = useSlots()
    public cardText = this.text
    public showText = true
    public viewCommentText = 'View original'
    public magicWand = MagicWand

    public get circleStyle() {
        let timeleft = 0
        if (this.undoTimeLeft > 15) {
            timeleft = 15
        } else if (this.undoTimeLeft > 0) {
            timeleft = this.undoTimeLeft
        }

        const perimeter = 2 * Math.PI * (this.circleSize / 4)
        const remain = (timeleft / 15) * perimeter
        return {
            strokeDashoffset: perimeter - remain,
            strokeDasharray: perimeter,
        }
    }

    public get hasExtraDetails() {
        if (this.slots.extraDetails && this.slots.extraDetails()) {
            return this.slots.extraDetails()[0]['children']?.length
        }
        return false
    }

    public getRelativeDate(timestamp) {
        return this.undoTimeLeft
            ? timeSince(timestamp)
            : relativeDate(timestamp)
    }

    public async onToggleStarred(e) {
        e.stopPropagation()
        this.$emit('toggleStarred')
    }

    public getHighlightClass() {
        return this.highlight ? 'highlight' : ''
    }

    public viewDetail() {
        if (!this.showText) {
            return
        }
        this.$emit('viewDetail')
    }

    private onReact(data: IOnReact): void {
        this.$emit('onReact', data as IOnReact)
    }

    public toggleComment() {
        this.showText = false // First hide the text

        // Use nextTick instead of setTimeout to ensure proper Vue reactivity handling
        this.$nextTick(() => {
            // Update the content
            if (this.cardText === this.text) {
                this.cardText = this.hasAiQuestion
                    ? this.firstAiQuestion.original_message
                    : this.originalComment
            } else {
                this.cardText = this.hasAiQuestion
                    ? this.firstAiQuestion.value
                    : this.text
            }

            // Update the view state
            this.viewCommentText =
                this.viewCommentText === 'View original'
                    ? 'View moderated'
                    : 'View original'

            this.$emit(
                'toggleOriginalComment',
                this.viewCommentText === 'View moderated'
            )

            // Show the text again
            this.showText = true
        })
    }

    public get isAdmin() {
        return this.details?.permissions.includes(Permissions.RoleAdminBasics)
    }
}
</script>

<style lang="less" scoped>
@import '~@/mobile/src/styles/feedback-card.less';

.line-author-view-moderate-comment {
    display: flex;
    justify-content: space-between;

    img {
        margin-right: 3px;
        margin-top: -2px;
        filter: brightness(0) invert(0);
    }

    .btn-view-moderate-comment {
        display: flex;
        background: white;
        color: black;
        padding: 5px 8px;
        border-radius: 5px;
        font-size: 13px;
    }
}

.moderated-container {
    &--tag {
        display: inline-flex;
        align-items: center;
        background-color: #f4ebff;
        padding: 2px 8px;
        border-radius: 4px;
        color: #333;
        font-size: 10px;
        width: fit-content;
        margin-top: @gutterSpacing-base;
        img {
            height: 12px;
            width: auto;
            margin-right: 6px;
        }
    }
}
.card-label {
    padding-bottom: 0;
    color: @white90;
    font-family: @fontFamily;
    font-size: @fontSize-sm;
    font-style: normal;
    font-weight: @fontWeight-medium;
    line-height: @lineHeight-sm;
    letter-spacing: @letterSpacing-2xl;
    text-transform: uppercase;
}
</style>
