<template>
    <div>
        <OfflinePage v-if="!hasNetworkConnection" />
        <div v-else class="more-container">
            <Loading v-if="loading" :loading="loading" />
            <template v-for="option in moreOptions">
                <div
                    v-if="option.available"
                    :id="option.id"
                    :key="option.id"
                    class="more-line-container"
                    @click="option.onClick"
                >
                    <div class="more-line-content">
                        <Typography class="more-line-row" variant="body2">
                            <FontAwesomeIcon
                                class="more-line-icon"
                                :icon="option.icon"
                            />
                            {{ option.text }}
                        </Typography>
                    </div>
                </div>
            </template>
            <div class="footer">
                <div class="version">
                    <div>{{ domain }}</div>
                    <div>{{ decodeUserName }} | {{ userId }}</div>
                    <div>v{{ version }}</div>
                    <div v-if="currentlyCaching" class="cache-info">
                        <div>
                            <small>Offline data is currently caching...</small>
                        </div>
                    </div>
                    <div v-else-if="lastDataCached > 0" class="cache-info">
                        <div>
                            <small>Offline data cached: {{ fLastDataCached }}
                            </small>
                        </div>
                        <div>
                            <small>Time used caching: {{ lastTimeSpent }}</small>
                        </div>
                        <div>
                            <small>Cached size: {{ lastDataSize }}</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Watch, Vue } from 'vue-facing-decorator'
import { Action, Getter } from 'vuex-facing-decorator'
import { FontAwesomeIcon } from 'fontawesome/vue-fontawesome'
import {
    faGear,
    faMessage,
    faSignOut,
    faArrowRightArrowLeft,
    faUserSecret,
    faKey,
} from 'fontawesome/free-solid-svg-icons'
import Typography from '@/components/Rain/Typography/Typography.vue'
import { toggleBetaAppAccess } from '@/mobile/src/utils/betaFeatures'
import Loading from '@/components/Loading.vue'
import OfflinePage from '@/mobile/src/components/appV4/OfflinePage.vue'
import { isLocal } from '@/mobile/src/utils/misc'
import { decode as entityDecode } from 'he'
import { getAppVersion } from '@/mobile/src/utils/capacitor'
import { unRegisterPushDevice } from '@/mobile/src/api/auth'
import { useRouter } from 'vue-router'
import { stopBackgroundTasks } from '@/mobile/src/services/background-tasks'

@Component({
    components: {
        OfflinePage,
        Loading,
        FontAwesomeIcon,
        Typography,
    },
})
export default class EvenMore extends Vue {
    @Action dispatchStoreLogout
    @Getter $companyVars
    @Getter hasNetworkConnection!: boolean
    @Getter userHasMlp
    @Getter domain
    @Getter userName
    @Getter userId
    @Getter permissions
    @Getter lastDataCached!: number
    @Getter currentlyCaching!: boolean
    @Getter pushNotificationToken
    @Getter userHasMfa

    @Getter lastTimeSpent!: number

    @Getter lastDataSize!: number

    @Getter isImpersonating

    public version = ''
    public loading = false

    private router = useRouter()

    public get moreOptions() {
        return [
            {
                id: 'settings',
                icon: faGear,
                text: 'Settings',
                onClick: () => this.openSettingsPage(),
                available: true,
            },
            {
                id: 'provide-feedback',
                icon: faMessage,
                text: 'Provide feedback',
                onClick: () => null,
                available: true,
            },
            {
                id: 'switch-to-original-app',
                icon: faArrowRightArrowLeft,
                text: 'Switch to original app',
                onClick: () => this.switchToOriginalApp(),
                available: true,
            },
            {
                id: 'view-as-someone-else',
                icon: faUserSecret,
                text: 'View as someone else',
                onClick: () => this.router.push({ name: 'viewAs' }),
                available: this.canSwitchUser,
            },
            {
                id: 'setup-mfa',
                icon: faKey,
                text: 'Setup multi factor authentication',
                onClick: () => this.routeMFA(),
                available: this.$companyVars.has_mfa,
            },
            {
                id: 'signout',
                icon: faSignOut,
                text: 'Logout',
                onClick: this.logout,
                available: true,
            },
        ]
    }

    private async mounted() {
        this.version = await getAppVersion()
    }

    private get decodeUserName() {
        return entityDecode(this.userName)
    }

    @Watch('userHasMlp', { immediate: true })
    private swapToEvenMore() {
        if (!this.userHasMlp) {
            this.router.push({ name: 'more' })
        }
    }

    private async routeMFA() {
        if (this.userHasMfa) {
            await this.router.push({ name: 'mfa-reset' })
        } else {
            await this.router.push({ name: 'mfa-setup' })
        }
    }

    get fLastDataCached() {
        return new Date(this.lastDataCached * 1000).toLocaleString()
    }

    get canSwitchUser() {
        return (
            this.isImpersonating ||
            this.permissions.includes('ROLE_STAFF') ||
            this.permissions.includes('ROLE_ADMIN_BASICS')
        )
    }

    async logout() {
        this.loading = true
        if (this.$companyVars.has_mobile_offline_cache) {
            await stopBackgroundTasks()
        }
        await unRegisterPushDevice(this.pushNotificationToken)
        await this.dispatchStoreLogout()
        this.loading = false
    }

    public switchToOriginalApp() {
        this.loading = true
        toggleBetaAppAccess(false)
    }

    public openSettingsPage() {
        this.router.push({ name: 'evenMoreSettings' })
    }
}
</script>

<style lang="less" scoped>
@import '~@/styles/rain/variables.less';
@import '~@/styles/rain/colour.less';
@import '~@/styles/variables.less';
@import '~@/mobile/src/styles/option-lines.less';

.more-line {
    .option-line();
}

.footer {
    text-align: center;
    opacity: 0.5;

    .version {
        font-weight: 500;
        font-size: @fontSize-sm;
        letter-spacing: @letterSpacing-2xl;
        color: @grey40;
        padding: 5px 0 15px;
    }
}

.cache-info {
    padding-top: 8px;
}
</style>
